

body{
  font-family: 'Inter', sans-serif;
}

body[data-layout-mode="dark"] {
  font-family: 'Inter', sans-serif;
  background-color: lighten($gray-dark-200, 2%);
  color: $gray-dark-700;

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-dark-700;
  }
}
