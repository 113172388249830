.loader-container {
  width: 100%;
  
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: $white;
  opacity: 0.7;
  z-index: 999;
  .status {
    width: 50px;
     
    position: "absolute";
    left: "50%";
    top: "50%";
    transform: "translate(-50%; -50%)";
    .sr-only {
      display: none !important;
    }
  }
}

.chat-loader-container {
  width: 100%;
  
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: $white;
  opacity: 0.7;
  z-index: 999;
  .status {
    width: 50px;
     
    position: "absolute";
    left: "50%";
    top: "50%";
    transform: "translate(-50%; -50%)";
    .sr-only {
      display: none !important;
    }
  }
}

.modal-title-custom {
  .modal-title {
    font-size: 16px !important;
  }
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #ffffff;
    background: white url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
  }
}



body[data-layout-mode="dark"] {

  .modal-title-custom {
    .modal-title {
      font-size: 16px !important;
    }
    .close {
      box-sizing: content-box;
      width: 0.6em;
      height: 0.6em;
      padding: 0.25em 0.25em;
      color: #ffffff;
      background: white url("../images/close.svg") center/.6em auto
        no-repeat;
      border: 0;
      border-radius: 0.25rem;
      opacity: 0.5;
      span {
        display: none !important;
      }
    }
  }
}



.alert-dismiss-custom {
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.user-chat-topbar .topbar-bookmark .close {
  padding: 12px 20px;
}

.cursor-pointer {
  cursor: pointer;
}



